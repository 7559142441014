<template>
  <b-navbar
    class="d-md-flex"
    :class="{ 'bg-secondary' : isSticky === true }"
    :sticky="true"
    toggleable="md"
  >
    <b-container fluid class="px-0">
      <b-navbar-brand v-if="isSticky || windowWidth < 768" to="/" exact active-class="active">
        <img class="logo-header" src="../assets/imgs/logo_barliner.svg" alt="Barliner Workout">
      </b-navbar-brand>
      <div class="ml-auto">
        <div class="d-flex">
          <LanguageSelector class="mr-2 mr-md-3"></LanguageSelector>
          <div class="btn-hamburger" @click="$bvModal.show('webMenuModal')">
            <img src="../assets/icons/svg/menu-icon.svg">
          </div>
          <!-- Modal Component -->
          <b-modal id="webMenuModal" no-fade :hide-header="true" :hide-footer="true">
            <b-navbar class="d-flex">
              <b-container fluid class="px-0">
                <b-navbar-brand to="/" exact active-class="active">
                  <img class="logo-header mt-2" src="../assets/imgs/logo_barliner.svg" alt="Barliner Workout">
                </b-navbar-brand>
                <div class="ml-auto btn-close mr-2" @click="$bvModal.hide('webMenuModal')">
                  <img src="../assets/icons/svg/x-icon.svg" class="">
                </div>
              </b-container>
            </b-navbar>
            <b-container class="py-4 px-5">
              <b-row>
                <b-col cols="12" md="6" class="border-md-right">
                  <div class="mb-3 mb-md-5" @click="$bvModal.hide('webMenuModal')">
                    <router-link
                      exact exact-active-class="active-link"
                      class="link-page" to="/"
                    >Home</router-link>
                    <router-link
                      exact exact-active-class="active-link"
                      class="link-page"
                      :to="{ name: 'GroupTraining' }"
                    >Group Training</router-link>
                    <router-link
                      exact exact-active-class="active-link"
                      class="link-page"
                      :to="{ name: 'Personal' }"
                    >Personal training</router-link>
                    <router-link
                      exact exact-active-class="active-link"
                      class="link-page"
                      :to="{ name: 'Equipment' }"
                    >Training Kit</router-link>
                  </div>
                  <b-row class="mb-3 mb-md-5">
                    <b-col cols="12" md="6" @click="$bvModal.hide('webMenuModal')">
                      <router-link
                        exact exact-active-class="active-link"
                        class="link-page small"
                        :to="{ name: 'Inclusive' }"
                      >Inclusion policy</router-link>
                      <router-link
                        class="link-page small"
                        to="/privacy"
                      >Privacy</router-link>
                    </b-col>
                    <b-col cols="12" md="6" @click="$bvModal.hide('webMenuModal')">
                      <router-link
                        class="link-page small"
                        :to="{ name: 'Home', hash: '#trainers' }"
                      >Careers</router-link>
                      <router-link
                        class="link-page small"
                        to="/imprints"
                      >Imprints</router-link>
                    </b-col>
                  </b-row>
                  <div class="hide-mobile">
                    <b-row>
                      <b-col cols="12" md="6">
                        <h4 class="h4-title">Contact</h4>
                        <p class="p-bw">info@barliner-workout.de</p>
                      </b-col>
                      <b-col cols="12" md="6">
                        <h4 class="h4-title">Follow us</h4>
                        <div class="d-flex" @click="$bvModal.hide('webMenuModal')">
                          <a class="link-social-media mr-4" href="https://www.instagram.com/denvital/?hl=en" target="_blank">
                            <font-awesome-icon :icon="['fab', 'instagram']" class="fa-icon" size="lg" />
                          </a>
                          <a class="link-social-media mr-4" href="https://www.facebook.com/Barliner.Workout" target="_blank">
                            <font-awesome-icon :icon="['fab', 'facebook']" class="fa-icon" size="lg" />
                          </a>
                          <a class="link-social-media mr-4" href="https://www.youtube.com/user/BarlinerWorkout" target="_blank">
                            <font-awesome-icon :icon="['fab', 'youtube']" class="fa-icon" size="lg" />
                          </a>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col cols="4" offset="2" class="text-center hide-mobile">
                  <p class="p-bw-bold mb-0">Sweat at home with</p>
                  <h3 class="h3-bw">Barliner virtual workouts</h3>
                  <div class="img-virtual-workouts my-3"></div>
                  <b-button variant="outline-primary" class="w-100"
                    :to="{ name: 'LivestreamClasses' }"
                    @click="$bvModal.hide('webMenuModal')">Learn about our livestreams</b-button>
                  <div class="img-on-demand my-3"></div>
                  <b-button variant="outline-primary" :disabled="true" class="w-100">On demand tv - coming soon!</b-button>
                </b-col>
              </b-row>
            </b-container>
            <div class="footer-mobile">
              <b-container>
                <h4 class="h4-title mt-2">Contact</h4>
                <p class="p-bw">info@barliner-workout.de</p>
                <div class="d-flex" @click="$bvModal.hide('webMenuModal')">
                  <a class="link-social-media mr-4" href="https://www.instagram.com/denvital/?hl=en" target="_blank">
                    <font-awesome-icon :icon="['fab', 'instagram']" class="fa-icon" size="lg" style="color: white;"/>
                  </a>
                  <a class="link-social-media mr-4" href="https://www.facebook.com/Barliner.Workout" target="_blank">
                    <font-awesome-icon :icon="['fab', 'facebook']" class="fa-icon" size="lg" style="color: white;"/>
                  </a>
                  <a class="link-social-media mr-4" href="https://www.youtube.com/user/BarlinerWorkout" target="_blank">
                    <font-awesome-icon :icon="['fab', 'youtube']" class="fa-icon" size="lg" style="color: white;"/>
                  </a>
                </div>
              </b-container>
            </div>
          </b-modal>
        </div>
      </div>
    </b-container>
  </b-navbar>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'Header',
  components: {
    LanguageSelector: () => import('./LanguageSelector')
  },
  data () {
    return {
      isSticky: false,
      scrollPosition: 0,
      windowWidth: window.innerWidth
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    handleScroll (event) {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition >= 88) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    }
  }
})
</script>

<style lang="scss">
  #webMenuModal {
    background-color: white;
    padding: 0 !important;
    > .modal-dialog {
      max-width: 100%;
      margin: 0;

      > .modal-content {
        min-height: 100vh;
        border-radius: 0;
        border: 0;

        > .modal-body {
          padding: 0;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  .navbar {
    padding: 0.75rem 1rem;

    @include breakpoint(lg) {
      padding: 0 1rem;
    }
  }

  .footer-mobile {
    @extend .hide-desktop;
    background-color: $primary;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .logo-header {
    width: 150px;
    height: 24px;
    margin-left: -28px;

     @include breakpoint(md) {
      height: 40px;
      margin-left: 1rem;
    }
  }

  .nav-items {
    text-transform: uppercase;
    .nav-link {
      padding-right: 2rem !important;
      font-size: 18px;
      line-height: 1.22;
      letter-spacing: 2.25px;
    }
  }

  .link-page {
    cursor: pointer;
    display: block;
    font-family: $bw-bebas;
    font-size: 1.125rem;
    line-height: 1.22;
    letter-spacing: 3px;
    margin-bottom: 1rem;

    @include breakpoint(md) {
      font-size: 2rem;
      line-height: 1.25;
      letter-spacing: 2.67px;
    }

    &.small {
      font-size: 12px;
      line-height: 1.83;
      letter-spacing: 2px;
      margin-bottom: .5rem;

      @include breakpoint(md) {
        font-size: 1.125rem;
        line-height: 1.22;
        letter-spacing: 3px;
      }
    }
  }

  .link-page:hover {
    color: $error;
  }

  a:hover, a:visited, a:link, a:active
  {
      text-decoration: none;
  }

  .link-social-media {
    color: $primary;
    font-size: 1.25rem;
    line-height: 1.83;
    letter-spacing: 1.5px;
  }

  .h4-title {
    font-family: $bw-bebas;
    font-size: 1.125rem;
    line-height: 1.22;
    letter-spacing: 3px;
  }

  .img-virtual-workouts {
    background-repeat: no-repeat;
    height: 13rem;
    width: 100%;
    background-position: center center;
    background-size: 100% auto;
    background-image: url('../assets/imgs/landing_classes_livestream1.png');
  }

  .img-on-demand {
    background-repeat: no-repeat;
    height: 13rem;
    width: 100%;
    background-position: center center;
    background-size: 100% auto;
    background-image: url('../assets/imgs/landing_classes_in_person_800.png');
  }

  .btn-close {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    margin-top: 0;

     @include breakpoint(md) {
      width: 4rem;
      height: 4rem;
    }
  }

  .btn-hamburger {
    cursor: pointer;
    width: 1.5rem;

    @include breakpoint(md) {
      width: 4.5rem;
    }
  }

  .border-md-right {
    border-right: none;

    @include breakpoint(md) {
      border-right: 4px solid $primary;
    }
  }
</style>
